import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";

import Header from "./header";
import "./layout.css";
import GlobalStyle from "../globalStyles";
import styled from "styled-components";

const Main = styled.main`
  flex: 1 0 auto;
  position: relative;

  /* position: absolute; */
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
// const Footer = styled.footer`
//   flex-shrink: 0;
// `

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="preload" href="./layout.css" as="style" />

        {/* <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/logo.svg`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/logo.svg`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/logo.svg`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/logo.svg`}
          color="#ff4400"
        /> */}
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/logo.svg`} />
      </Helmet>
      <GlobalStyle />
      <Header siteTitle={title} />
      <div>
        <Main>{children}</Main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
