import React, { useState } from "react";
import styled from "styled-components";
import NavbarLinks from "./NavbarLinks";

const Navigation = styled.nav`
  /* height: 10vh; */
  display: flex;
  background-color: transparent;
  position: relative;
  /* justify-content: space-between; */
  /* text-transform: uppercase; */
  /* border-bottom: 2px solid #33333320; */
  /* margin-left: auto; */
  /* padding: 0 5vw; */
  z-index: 2;
  align-self: center;
  /* width: 70%; */
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 968px) {
    position: sticky;
    display: flex;
    margin-left: auto;
    /* height: 8vh; */
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`;

const Toggle = styled.div`
  display: none;
  height: 30px;
  cursor: pointer;
  /* padding: 0 10vw; */

  @media (max-width: 968px) {
    display: flex;
    z-index: 9999;
    position: absolute;
  }
`;

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  margin: 0 30px;
  @media (max-width: 968px) {
    flex-direction: column;
    position: fixed;
    justify-content: center;

    /* position: ${(props) => (props.open ? "relative" : "absolute")}; */

    width: 100%;
    /* justify-content: flex-start; */
    /* padding-top: 10vh; */
    margin: 0;
    background-color: #27213c;
    transition: all 0.3s ease-in;
    /* top: 14vh; */
    top: 0vh;
    /* display: ${(props) => (props.open ? "none" : "flex")}; */

    left: ${(props) => (props.open ? "-111%" : "0")};
  }
`;

const Hamburger = styled.div`
  background-color: white;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: white;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${(props) =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${(props) => (props.open ? "0" : "1")};
    transform: ${(props) => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`;
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Navigation>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <NavbarLinks />
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks />
        </Navbox>
      )}
    </Navigation>
  );
};

export default Navbar;
