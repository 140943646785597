import React from "react";
import styled, { css } from "styled-components";
import { Link, graphql, StaticQuery } from "gatsby";

const NavItem = styled(Link)`
  text-decoration: none;
  color: white;
  /* display: inline-block; */
  white-space: nowrap;
  /* margin: 0 1vw; */
  /* transition: all 200ms ease-in; */
  /* position: relative; */
  font-family: "Nexa";
  height: 100%;
  display: flex;
  place-items: center;
  padding: 0 45px;

  display: flex;
  position: relative;

  :hover {
    /* background: #9a1c1f;
    transition: all 0.4s ease-in;

    ::after {
      width: 100%;
    } */
  }
  &.active {
    /* color: #9a1c1f; */
    background: #9a1c1f;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
    height: 20%;

    &.active {
      color: #9a1c1f;
      background: transparent;
    }
  }
`;
const NavItemUnClickable = styled.a`
  text-decoration: none;
  color: white;
  /* display: inline-block; */
  white-space: nowrap;
  /* margin: 0 1vw; */
  /* transition: all 200ms ease-in; */
  /* position: relative; */
  font-family: "Nexa";
  /* height: 100%; */
  display: flex;
  place-items: center;
  padding: 0 45px;

  display: flex;
  position: relative;

  :hover {
    /* background: #9a1c1f;
    transition: all 0.4s ease-in;

    ::after {
      width: 100%;
    } */
  }
  &.active {
    /* color: #9a1c1f; */
    background: #9a1c1f;
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
    &.active {
      color: #9a1c1f;
      background: transparent;
    }
  }
`;
const MobileMenu = styled.div`
  display: none;

  @media (max-width: 968px) {
    background: pink;
    display: flex;
  }
`;
const NavMenu = styled.ul`
  display: none;
  flex-direction: column;
  list-style: none;
  position: absolute;
  top: 126px;
  background: #9a1c1f;
  color: white;
  width: 100%;
  /* height: 200px; */
  font-size: 0.8rem;
  margin: 0;
  text-transform: capitalize;

  /* text-align: center; */
  /* padding: 0 10px; */
  a {
    font-family: "Nexa";
    color: white;
    text-decoration: none;
  }
  li {
    padding-left: 10px;
    :hover {
      background: #27213c;
    }
  }
  @media (max-width: 968px) {
    /* display: none; */
    position: unset;
    background: transparent;
    a {
      font-family: "Nexa Bold";
      :hover {
        color: #efca69;
      }
    }
    li {
      margin: auto;
      padding: 5px 0px;
    }
  }
`;
const NavItemWrapper = styled.div`
  /* display: flex;
    flex-direction: row; */
  position: relative;
  /* height: 100%; */
  height: 100%;
  justify-content: center;
  @media (max-width: 968px) {
    /* min-height: 10%; */
    /* height: 50px; */
  }
  display: flex;
  flex-direction: column;
  :hover {
    background: #9a1c1f;
    @media (max-width: 968px) {
      background: transparent;
    }
    ${NavItem} , ${NavItemUnClickable} {
      /* background: #9a1c1f; */
      /* transition: all 0.4s ease-in; */

      ::after {
        width: 100%;
      }
      @media (max-width: 968px) {
        color: #9a1c1f;
      }
    }

    ${NavMenu} {
      transition: all 0.9s ease-in;
      @media (max-width: 968px) {
        /* display: none; */
      }
      display: block;
    }
  }
`;
const NavbarLinks = () => {
  const { menuIsOpen, setMenuIsOpen } = React.useState(false);
  return (
    <>
      <NavItemWrapper>
        <NavItem activeClassName="active" to="/about">
          About Us
        </NavItem>
      </NavItemWrapper>
      <NavItemWrapper>
        <NavItemUnClickable activeClassName="active">
          Services
        </NavItemUnClickable>
        {/* <MobileMenu>
          <ul>
            <li>
              <a href="#">project1</a>
            </li>
            <li>
              <a href="#">Project2</a>
            </li>
            <li>
              <a href="#">project3</a>
            </li>
          </ul>
        </MobileMenu> */}
        <NavMenu>
          <StaticQuery
            query={graphql`
              query Nav {
                allMarkdownRemark(
                  sort: { order: DESC, fields: [frontmatter___date] }
                  filter: {
                    frontmatter: { templateKey: { eq: "service-post" } }
                  }
                ) {
                  edges {
                    node {
                      excerpt(pruneLength: 400)
                      id
                      fields {
                        slug
                      }
                      frontmatter {
                        title
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                      }
                    }
                  }
                }
              }
            `}
            render={(data, count) => {
              return (
                <>
                  {data.allMarkdownRemark.edges.map((edge, index) => (
                    <li key={index}>
                      <Link to={edge.node?.fields.slug}>
                        {edge.node?.frontmatter.title}
                      </Link>
                    </li>
                  ))}
                </>
              );
            }}
          />
        </NavMenu>
      </NavItemWrapper>
      <NavItemWrapper>
        <NavItem activeClassName="active" to="/partners">
          Partners
        </NavItem>
      </NavItemWrapper>
      <NavItemWrapper>
        <NavItem activeClassName="active" to="/contact">
          Contact Us
        </NavItem>
      </NavItemWrapper>
    </>
  );
};

export default NavbarLinks;
