import { createGlobalStyle } from "styled-components"
import NexaBoldOTF from "../static/fonts/Nexa-Bold.otf"
import NexaThinOTF from "../static/fonts/Nexa-Light.otf"
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Nexa Bold';
    src: url(${NexaBoldOTF}) format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Nexa';
    src: url(${NexaThinOTF}) format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  `
export default GlobalStyle
