import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Navbar from "./Navbar/Navbar";
import Logo from "./Navbar/Logo";

//red #9a1c1f
//blue #27213c
//'yellow' #efca69
//green #68b478
//orange #d36234
// light blue #448ecc

const HeaderStyle = styled.div`
  background: #27213cc2;
  position: fixed;
  width: 100%;
  z-index: 2;

  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 126px;
  justify-content: space-between;
`;
const Header = ({ siteTitle }) => {
  return (
    <HeaderStyle>
      <Logo siteTitle={siteTitle} />
      <Navbar />
    </HeaderStyle>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
