import React from "react"
import LogoIcon from "../../assets/miratech.svg"
import styled from "styled-components"
import { Link } from "gatsby"

const LogoStyle = styled(LogoIcon)``
const LogoWrap = styled.div`
  ${LogoStyle} {
  }

  cursor: pointer;
  @media (max-width: 968px) {
    ${LogoStyle} {
      width: 60%;
    }
  }
`
const Logo = ({ siteTitle }) => (
  <LogoWrap as={Link} to="/" title={siteTitle}>
    <LogoStyle />
  </LogoWrap>
)

export default Logo
