import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Facebook from "../assets/facebook.svg";

const Rights = styled.p`
  color: white;
  font-family: "Nexa";
  font-size: 12px;
  margin: 0;
`;
const SocialMedia = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
  a {
    display: flex;
  }
`;
const FooterStyle = styled.footer`
  flex-shrink: 0;
  padding: 0.5rem;
  background-color: #9a1c1f;
  justify-content: center;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Footer = class extends React.Component {
  render() {
    return (
      <FooterStyle>
        {/* <SocialMedia>
          <a title="facebook" href="https://facebook.com">
            <Facebook />
          </a>
        </SocialMedia> */}

        <Rights>© {new Date().getFullYear()}, Built For Miratech</Rights>
      </FooterStyle>
    );
  }
};

export default Footer;
